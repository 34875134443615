import React from 'react';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import TechIcons from '../TechIcons';
import TypographyItem from './TypographyItem';
import TechFeaturesListMobile from '../TechFeaturesListMobile';

import java from '../../../assets/img/java-icon.png';
import ionic from '../../../assets/img/ionic-icon.png';
import swift from '../../../assets/img/swift-icon.png';
import reactNative from '../../../assets/img/react-native.png';
import html3 from '../../../assets/img/html3.png';
import objectiveC from '../../../assets/img/apple-icon.png';
import android from '../../../assets/img/android-icon-grey.png';
import mobile from '../../../assets/img/mobile-img2.png';
import line from '../../../assets/img/native-apps.svg';
import apple from '../../../assets/img/apple-icon-grey2.png';
import html from '../../../assets/img/html5-grey.png';

import { media } from '../../../globalStyles';

const icons = [
	{
		icon: java,
		alt: 'java logo',
	},
	{
		icon: ionic,
		alt: 'ionic logo',
	},
	{
		icon: swift,
		alt: 'swift logo',
	},
	{
		icon: reactNative,
		alt: 'react native logo',
	},
	{
		icon: html3,
		alt: 'dark html 5 logo',
	},
	{
		icon: objectiveC,
		alt: 'objective c logo',
	},
];

const StyledMobileAppProgramming = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1400px;
	margin: 0 auto 130px;
	align-items: center;
	${media.maxSm`
		margin-bottom: 20px	
  `}
	.b-col {
		flex: 1;
		display: flex;
		flex-direction: column;
		&.left {
			align-items: flex-end;
		}
		&.right {
			align-items: flex-start;
			padding-top: 30px;
		}
	}

	.typography-cover {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		position: relative;
	}
	.typography-center-item {
		align-self: center;
		text-align: center;
		max-width: 100px;
		img {
			width: 100%;
			height: auto;
		}
		${media.maxXs`
			margin: auto;
			max-width: 100px;
		`}
	}

	.typography-img-line {
		position: absolute;
		left: 25%;
		top: 70px;
		width: 18%;
		height: auto;
		display: block;
		${media.laptopS`
			width: 16%;
			left: 26%;
		`}
		${media.maxMd`
			display: none;
		`}
	}
	.typography-img-line__revert {
		transform: scaleX(-1);
		left: auto;
		right: 25%;
		${media.laptopS`
			width: 16%;
			right: 26%;
		`}
	}
`;
const features = [
	'Education',
	'Air & Space',
	'Healthcare',
	'Real Estate',
	'Automotive',
];
const MobileAppProgramming = () => (
	<ContentSection
		fluid
		title="Mobile Application Programming"
		subtitle="Reach them wherever they are with a customized mobile app"
	>
		<StyledMobileAppProgramming>
			<div className="typography-cover">
				<img className="typography-img-line hidden-sm" src={line} alt="" />
				<img
					className="typography-img-line typography-img-line__revert hidden-sm"
					src={line}
					alt=""
				/>
				<TypographyItem
					link="/technologies-android"
					title="Android Apps"
					icon={android}
					altText="android icon"
				>
					Create fast, high-performing, and immersive android apps by depending
					on the developers at <b>PixelTeh</b>. We build end-to-end android
					mobile applications that move your business forward. Reach out to a
					global market by letting us design an app for maximum visibility and
					influence.
				</TypographyItem>

				<div className="typography-center-item">
					<img src={mobile} alt="orange mobile phone" />
				</div>

				<TypographyItem
					altText="apple logo"
					link="/technologies-ios"
					title="IOS Apps"
					icon={apple}
				>
					In order to compete in your market, you need an iOS app that is
					innovative, bug-free, and even trend-setting. For a personalized
					mobile application that captures attention and drives profits, turn to
					our team. Don’t miss out on the latest in mobile programming. Contact
					us today!
				</TypographyItem>
				<TypographyItem
					link="/technologies-crossplatform"
					title="Cross-Platform Apps"
					icon={html}
					altText="html 5 logo"
				>
					Achieve greater market penetration with an app that can be used over
					multiple platforms. The apps we develop take advantage of the latest
					multi-platform mobile development programming to support hardware of
					various mobile brands. These advanced apps work with iOS, Android,
					Windows, and more.
				</TypographyItem>
			</div>
			<TechFeaturesListMobile
				features={features}
				link="/technologies-crossplatform"
			/>
		</StyledMobileAppProgramming>
		<TechIcons icons={icons} />
	</ContentSection>
);

export default MobileAppProgramming;
