import React from 'react';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import TechDescription from '../TechDescription';
import TechIcons from '../TechIcons';
import Feature from './Feature';

import python from '../../../assets/img/python-icon.png';
import node from '../../../assets/img/node-icon.png';
import java from '../../../assets/img/java-icon.png';
import etherum from '../../../assets/img/etherum-icon.png';
import bitcoin from '../../../assets/img/bitcoin-alt-logo.png';
import bitcoinLogo from '../../../assets/img/bitcoin-logo.png';
import { media } from '../../../globalStyles';
import TechFeaturesListMobile from '../TechFeaturesListMobile';

const icons = [
	{
		icon: python,
		alt: 'python logo',
	},
	{
		icon: node,
		alt: 'node logo',
	},
	{
		icon: java,
		alt: 'java logo',
	},
	{
		icon: etherum,
		alt: 'Ethereum logo',
	},
	{
		icon: bitcoin,
		alt: 'bitcoin logo2',
	},
];
const StyledBlockChain = styled.div`
	display: flex;
	justify-content: center;
	max-width: 1400px;
	margin: 0 auto 111px;
	align-items: center;
	.b-col {
		flex: 1;
		display: flex;
		flex-direction: column;
		&.left {
			align-items: flex-end;
		}
		&.right {
			align-items: flex-start;
			padding-top: 30px;
		}
	}
	${media.maxSm`
		margin-bottom: 58px;
		.logo{
			width: 134px;
		}
	`}
`;
const features = [
	'Real-Time Payments',
	'Fraud Reduction',
	'Smart Contracts',
	'Access to Everyone',
	'Proof of Ownership',
	'Immediate Transactions',
];
const Blockchain = () => (
	<ContentSection
		fluid
		title="Blockchain & Cryptocurrencies"
		subtitle="Making financial transactions and trading more secure and accessible"
	>
		<StyledBlockChain>
			<div className="b-col left hidden-sm">
				<Feature width={50} margin="-30px">
					Real-Time Payments
				</Feature>
				<Feature width={60} margin="-30px">
					Fraud Reduction
				</Feature>
				<Feature width={70}>Smart Contracts</Feature>
			</div>
			<img className="logo" src={bitcoinLogo} alt="bitcoin logo" />
			<div className="b-col right hidden-sm">
				<Feature width={70} reverse>
					Access to Everyone
				</Feature>
				<Feature width={60} reverse>
					Proof of Ownership
				</Feature>
				<Feature width={50} reverse>
					Immediate Transactions
				</Feature>
			</div>
		</StyledBlockChain>
		<TechDescription
			title="A Secure & Better Way to Pay for Goods & Services"
			linkTo="/technologies-blockchain"
		>
			What is blockchain? It’s a technology that allows digital information to
			be distributed by using an incorruptible digital ledger. Although somewhat
			hard to understand, it is a very secure way of creating tamper-proof
			databases that can be used for all types of transactions. Information
			within the blockchain is shared and continually reconciled. The blockchain
			database isn’t stored in any single location, meaning the records it keeps
			are truly public and easily verifiable. The developers at <b>PixelTeh</b>{' '}
			design and program blockchains and cryptocurrency applications for
			businesses around the world. We are a leading blockchain developer that
			produces impressive results for our clients and their intended users.
		</TechDescription>
		<TechFeaturesListMobile
			features={features}
			link="/technologies-blockchain"
		/>
		<TechIcons icons={icons} />
	</ContentSection>
);

export default Blockchain;
