import React from 'react';
import styled from 'styled-components';

import ContentSection from '../../ContentSection';
import ProjectTypesLine from './ProjectTypesLine';
import ProjectType from './ProjectType';

import bracketLeft from '../../../assets/img/brackets-left-1.png';
import bracketRight from '../../../assets/img/brackets-right-1.png';
import bracketMobile from '../../../assets/img/brackets-mobile.png';
import python from '../../../assets/img/python-icon.png';
import php from '../../../assets/img/php-icon.png';
import node from '../../../assets/img/node-icon.png';
import angular from '../../../assets/img/angular-icon.png';
import django from '../../../assets/img/django-icon.png';
import laravel from '../../../assets/img/laravel-icon.png';
import react from '../../../assets/img/react-icon.png';
import TechIcons from '../TechIcons';
import TechDescription from '../TechDescription';
import TechFeaturesListMobile from '../TechFeaturesListMobile';
import { media } from '../../../globalStyles';

const StyledFullstack = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 1400px;
	margin: 0 auto 93px;
	.content {
		flex: 1;
	}
	.brackets-mobile {
		margin: 0 auto;
	}
	${media.maxSm`
		flex-direction: column;
	`}
`;

const icons = [
	{
		icon: python,
		alt: 'python logo',
	},
	{
		icon: php,
		alt: 'php logo',
	},
	{
		icon: node,
		alt: 'node logo',
	},
	{
		icon: angular,
		alt: 'angular logo',
	},
	{
		icon: django,
		alt: 'Django logo',
	},
	{
		icon: laravel,
		alt: 'Laravel logo',
	},
	{
		icon: react,
		alt: 'reactjs logo',
	},
];
const featuresMobile = [
	'Custom Web Application',
	'eCommerce Platforms',
	'Maps & Direction Websites',
	'International Websites',
	'Online Communities & Forums',
	'International Websites',
	'Auction & Gaming Websites',
];
const Fullstack = () => (
	<ContentSection
		fluid
		title="Full Stack Web Development"
		subtitle="Complete programming from design to deployment"
		seoTitle={'h2'}
		seoSubTitle={'h3'}
	>
		<StyledFullstack>
			<img src={bracketMobile} alt="" className="visible-sm brackets-mobile" />
			<div className="bracket hidden-sm">
				<img src={bracketLeft} alt="" />
			</div>
			<div className="content hidden-sm">
				<ProjectTypesLine top>
					<ProjectType text="Сustom Web Application" height={174} squareIsBig />
					<ProjectType text="Maps & Direction Websites" height={214} />
					<ProjectType text="Online Communities & Forums" height={267} />
					<ProjectType text="International Websites" height={160} />
				</ProjectTypesLine>
				<ProjectTypesLine>
					<ProjectType
						text="eCommerce Platforms"
						reverse
						height={170}
						width={30}
					/>
					<ProjectType
						text="VR & AR App Development"
						reverse
						squareIsBig
						height={178}
					/>
					<ProjectType
						text="Personal & Professional Blogs"
						reverse
						height={210}
					/>
					<ProjectType
						text="Auction & Gaming Websites"
						reverse
						squareIsBig
						height={273}
						width={29}
					/>
				</ProjectTypesLine>
			</div>
			<div className="bracket hidden-sm">
				<img src={bracketRight} alt="" />
			</div>
		</StyledFullstack>
		<TechDescription
			title="The Highest Quality Services – from Beginning to End"
			linkTo="/technologies-full-stack"
		>
			Bring your ideas and aspirations to life with the expert full stack
			developers at <b>PixelTeh</b>. We’re not simply a team of backend and
			frontend programmers. We do it all! Our full stack developers handle every
			aspect of the project and any corresponding challenges, from design to
			deployment. In other words, we have specialized knowledge across all
			stages of development – languages, technologies, and platforms. You could
			say we see the forest AND the trees – the big and small aspects of every
			project. Most importantly, we stay engaged with our clients during all
			phases of the project. For state-of-the-art programming that truly
			performs, trust our full stack development company to exceed your goals.
		</TechDescription>
		<TechFeaturesListMobile
			features={featuresMobile}
			link="/technologies-full-stack"
		/>
		<TechIcons icons={icons} />
	</ContentSection>
);

export default Fullstack;
