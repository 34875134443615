import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors } from '../../../globalStyles';
import FeatureDescription from '../FeatureDescription';

const StyledProjectType = styled.div`
	display: flex;
	flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
	align-items: center;
	min-height: ${({ height }) => `${height}px`};
	${FeatureDescription} {
		padding: 29px 0 12px;
	}
	.line {
		flex: 1;
		width: 1px;
		border-right: 1px dashed #ccc;
	}
	.empty-square,
	.grey-square {
		width: 10px;
		height: 10px;
	}
	.grey-square {
		background-color: #c9c9c9;
		border: none;
		${({ squareIsBig }) =>
			squareIsBig &&
			css`
				width: 14px;
				height: 14px;
			`}
	}
	.empty-square {
		border: 1px solid ${colors.orange};
	}
	${({ width }) =>
		width &&
		css`
			width: ${width}%;
		`}
`;

const ProjectType = ({ text, ...rest }) => (
	<StyledProjectType {...rest}>
		<FeatureDescription>{text}</FeatureDescription>
		<span className="empty-square" />
		<span className="line" />
		<span className="grey-square" />
	</StyledProjectType>
);

ProjectType.propTypes = {
	text: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	squareIsBig: PropTypes.bool,
	reverse: PropTypes.bool,
};

export default ProjectType;
