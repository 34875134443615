import React from 'react';
import styled from 'styled-components';
import P from '../_common/P';
import PageDescription from '../PageDescription';
import { Link } from 'gatsby';
import { colors } from '../../globalStyles';

const ExtendedP = styled(P)`
	a {
		color: ${colors.blackTitle};
	}
`;
const DescriptionBlock = () => (
	<PageDescription
		title="Emerging Technologies <br/>
        for Businesses, Startups, & Entrepreneurs"
		bgColor="white"
	>
		<ExtendedP>
			<b>PixelTeh</b> is a mobile/web development and programming company that
			uses new technology and innovation, as well as our expertise, to build a
			brighter future for our clients. From{' '}
			<Link to="/technologies-machine-learning">
				artificial intelligence and machine learning
			</Link>{' '}
			to virtual and augmented reality, we push the boundaries of digital
			transformation. Our team of talented programmers and developers stay
			abreast with technologies that are breaking out every day. To that end, we
			make sure the platforms we create are dependable and pioneering. Due to
			our years of experience in the industry, you can trust <b>PixelTeh</b> to
			achieve the results you want . . . and more!
		</ExtendedP>
	</PageDescription>
);

export default DescriptionBlock;
