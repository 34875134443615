import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors, fonts, fontSizes, media } from '../../../globalStyles';

const videoStyles = css`
	top: 3%;
	left: 25%;
	@media (max-width: 1110px) {
		top: -7%;
		left: 29%;
	}
	.description-wrapper {
		transform: rotate(58deg);
		left: -16%;
		top: 183px;
	}
`;
const faceStyles = css`
	top: 30%;
	left: 21%;
	@media (max-width: 1110px) {
		top: 20%;
		left: 15%;
	}
	.description-wrapper {
		transform: rotate(42deg);
		left: -4%;
		top: 76px;
	}
`;
const homeStyles = css`
	top: 54%;
	left: 11%;
	@media (max-width: 1110px) {
		top: 65%;
		left: 12%;
	}
	.description-wrapper {
		transform: rotate(19deg);
		left: -1%;
		top: 50px;
	}
	.icon {
		margin: 24px -15px 0 13px;
	}
`;
const carStyles = css`
	top: 2%;
	right: 24%;
	flex-direction: row-reverse;
	@media (max-width: 1110px) {
		top: -5%;
		right: 28%;
	}
	.description-wrapper {
		transform: rotate(-55deg);
		left: 19%;
		top: 140px;
		align-items: flex-end;
	}
	.icon {
		margin-left: 10px;
	}
`;
const assistantStyles = css`
	top: 31%;
	right: 12%;
	flex-direction: row-reverse;
	@media (max-width: 1110px) {
		top: 19%;
		right: 8%;
	}
	.description-wrapper {
		transform: rotate(-28deg);
		left: 5%;
		top: 85px;
		align-items: flex-end;
	}
	.icon {
		margin-top: 48px;
	}
`;
const securityStyles = css`
	top: 58%;
	right: 6%;
	flex-direction: row-reverse;
	@media (max-width: 1110px) {
		top: 60%;
		right: 7%;
	}
	.description-wrapper {
		transform: rotate(-17deg);
		left: 0;
		top: 29px;
		align-items: flex-end;
	}
	.icon {
		margin: 30px 8px -8px -8px;
	}
`;
const StyledUseCase = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	@media (max-width: 1110px) {
		flex-direction: column;
	}
	.icon {
		@media (max-width: 1110px) {
			margin: 0 !important;
		}
	}
	.description-wrapper {
		display: flex;
		flex-direction: column;
		position: relative;
		@media (max-width: 1110px) {
			transform: rotate(0) !important;
			top: 10px !important;
			left: 0 !important;
		}
		.description {
			padding-left: 50px;
			padding-right: 50px;
			color: ${colors.blackTitle};
			font-family: ${fonts.robotoRegular};
			font-size: ${fontSizes.p};
		}
	}
	.line {
		width: 100%;
		${media.laptop`width: 90%;`};
		@media (max-width: 1110px) {
			display: none;
		}
	}
	${({ name }) => {
		switch (name) {
			case 'video':
				return videoStyles;
			case 'face':
				return faceStyles;
			case 'home':
				return homeStyles;
			case 'car':
				return carStyles;
			case 'assistant':
				return assistantStyles;
			case 'security':
				return securityStyles;
		}
	}}
`;

const UseCase = ({ icon, line, children, name, altText }) => (
	<StyledUseCase name={name} className="hidden-sm">
		<img src={icon} className="icon" alt={altText} />
		<div className="description-wrapper">
			<span className="description">{children}</span>
			<img src={line} alt="" className="line" />
		</div>
	</StyledUseCase>
);

UseCase.propTypes = {
	icon: PropTypes.string,
	name: PropTypes.string,
	line: PropTypes.string,
	children: PropTypes.any,
	altText: PropTypes.string,
};

export default UseCase;
