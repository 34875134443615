import React from 'react';
import { shape, string } from 'prop-types';
import PageLayout from '../layouts/PageLayout';
import SubpageTopCover from '../components/SubpageTopCover';
import DescriptionBlock from '../components/TechnologiesPage/DescriptionBlock';
import Fullstack from '../components/TechnologiesPage/Fullstack';
import Blockchain from '../components/TechnologiesPage/Blockchain';
import MobileAppProgramming from '../components/TechnologiesPage/MobileAppProgramming';
import ContactForm from '../components/ContactForm';
import AI from '../components/TechnologiesPage/AI';
import ProgrammingHub from '../components/ProgrammingHub';
import { useHeroPictureTechnologies } from '../hooks/use-hero-picture-technologies';
import { technologies } from '../seoConfig';

const TechnologiesPage = ({ location }) => {
	const img = useHeroPictureTechnologies();
	return (
		<PageLayout location={location} seoConfig={technologies}>
			<SubpageTopCover
				backgroundImg={img}
				altText={'pixelteh team with computers'}
			>
				Pushing the Boundaries of Digital Transformation
			</SubpageTopCover>
			<DescriptionBlock />
			<Fullstack />
			<Blockchain />
			<AI />
			<MobileAppProgramming />
			<ContactForm label="Technologies Form" />
			<ProgrammingHub />
		</PageLayout>
	);
};
TechnologiesPage.propTypes = {
	location: shape({
		pathname: string.isRequired,
	}).isRequired,
};
export default TechnologiesPage;
