import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import FeatureDescription from '../FeatureDescription';
import { media } from '../../../globalStyles';

const StyledFeature = styled.div`
	display: flex;
	flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
	align-items: center;
	padding: 10px 0;
	.dot {
		width: 17px;
		height: 17px;
		background-color: #c9c9c9;
		border-radius: 50%;
	}
	.line {
		height: 1px;
		background-color: #c9c9c9;
		flex: 1;
	}
	${FeatureDescription} {
		${({ reverse }) =>
			reverse
				? css`
						margin-left: 8px;
				  `
				: css`
						margin-right: 8px;
				  `}
	}
	${({ width }) =>
		width &&
		css`
			width: ${width}%;
			${media.maxMd`
				width: ${width + 20}%;
			`}
		`}
	${({ margin }) =>
		margin &&
		css`
			margin-right: ${margin};
		`}
`;

const Feature = ({ children, ...rest }) => (
	<StyledFeature {...rest}>
		<FeatureDescription>{children}</FeatureDescription>
		<span className="dot" />
		<span className="line" />
	</StyledFeature>
);

Feature.propTypes = {
	children: PropTypes.string,
	reverse: PropTypes.bool,
	width: PropTypes.number,
	margin: PropTypes.string,
};

export default Feature;
