import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import H3 from '../_common/H3';
import P from '../_common/P';
import moreIcon from '../../assets/img/more-icon.png';
import styled from 'styled-components';
import TextContainer from '../_common/TextContainer';
import { fonts, media, colors } from '../../globalStyles';

const StyledTextContainer = styled(TextContainer)`
	margin-bottom: 97px;
	${P} {
		text-align: left;
	}
	${H3} {
		margin-bottom: 35px;
		font-family: ${fonts.robotoMedium};
		font-size: 24px;
	}
	a {
		text-decoration: none;
		color: ${colors.blackTitle};
		font-family: ${fonts.openSansBold};
	}
	${media.maxSm`
		margin-bottom: 0;
		${P} {
			font-family: ${fonts.openSansLightItalic};
			line-height: 26px;
			text-align: center;
			font-size: 16px;
		}
		a {
			display: none;
		}
	`}
`;
const TechDescription = ({ title, children, linkTo }) => (
	<StyledTextContainer>
		<H3 className="hidden-sm">{title}</H3>
		<P>
			{children}{' '}
			<Link to={linkTo}>
				<img src={moreIcon} alt="" />{' '}
			</Link>
		</P>
	</StyledTextContainer>
);

TechDescription.propTypes = {
	title: PropTypes.string,
	children: PropTypes.any,
	linkTo: PropTypes.string,
};
export default TechDescription;
