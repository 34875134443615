import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import TechDescription from '../TechDescription';
import UseCase from './UseCase';

import head from '../../../assets/img/head.png';
import carIcon from '../../../assets/img/car-icon.png';
import carLine from '../../../assets/img/car-automation.svg';
import smartIcon from '../../../assets/img/smart-icon.png';
import smartLine from '../../../assets/img/smart-personal-assistants.svg';
import securityLine from '../../../assets/img/smart-personal-assistants.svg';
import securityIcon from '../../../assets/img/security-icon.png';
import videoIcon from '../../../assets/img/jostick-icon.png';
import videoLine from '../../../assets/img/video-gaming.svg';
import faceIcon from '../../../assets/img/face-icon.png';
import faceLine from '../../../assets/img/face-recognition.svg';
import homeIcon from '../../../assets/img/home-icon.png';
import homeLine from '../../../assets/img/smart-home.svg';
import python from '../../../assets/img/python-icon.png';
import amazon from '../../../assets/img/amazon-icon.png';
import docker from '../../../assets/img/docker-icon.png';
import django from '../../../assets/img/django-border-icon.png';
import react from '../../../assets/img/react-icon.png';
import mysql from '../../../assets/img/mysql-icon.png';
import TechIcons from '../TechIcons';
import { media } from '../../../globalStyles';
import TechFeaturesListMobile from '../TechFeaturesListMobile';

const icons = [
	{
		icon: python,
		alt: 'python logo',
	},
	{
		icon: amazon,
		alt: 'amazon web services icon',
	},
	{
		icon: docker,
		alt: 'docker logo',
	},
	{
		icon: django,
		alt: 'django logo',
	},
	{
		icon: react,
		alt: 'reactjs logo',
	},
	{
		icon: mysql,
		alt: 'mysql logo',
	},
];
const features = [
	'Video Gaming',
	'Face Reogniton',
	'Smart Homes',
	'Car Automation',
	'Smart Personal Assistants',
	'Security Surveillance',
];
const StyledAI = styled.div`
	padding-top: 332px;
	position: relative;
	max-width: 1370px;
	margin: 0 auto 73px;
	${media.laptop`padding-top: 270px;`};
	@media (max-width: 1110px) {
		padding-top: 70px;
	}
	${media.maxSm`padding-top: 0;`}
	.head-wrapper {
		max-width: 338px;
		max-height: 168px;
		margin: 0 auto;
		${media.maxMd`
			max-width: 200px;
		`}
		img {
			width: 100%;
		}
	}
`;
const AI = () => (
	<ContentSection
		fluid
		title="AI & Machine Learning"
		subtitle="A smarter way to do business and simplify your life"
	>
		<StyledAI>
			<UseCase
				name="video"
				icon={videoIcon}
				line={videoLine}
				altText="video game controller"
			>
				Video Gaming
			</UseCase>
			<UseCase
				name="face"
				icon={faceIcon}
				line={faceLine}
				altText="smiley face"
			>
				Face Recognition
			</UseCase>
			<UseCase name="home" icon={homeIcon} line={homeLine} altText="home icon">
				Smart Homes
			</UseCase>
			<UseCase
				name="car"
				icon={carIcon}
				line={carLine}
				altText="smart car icon"
			>
				Car Automation
			</UseCase>
			<UseCase
				name="assistant"
				icon={smartIcon}
				line={smartLine}
				altText="personal assistant icon"
			>
				Smart Personal Assistants
			</UseCase>
			<UseCase
				name="security"
				icon={securityIcon}
				line={securityLine}
				altText="security camera icon"
			>
				Security Surveillance
			</UseCase>
			<div className="head-wrapper">
				<img src={head} alt="orange ai brain" />
			</div>
		</StyledAI>
		<TechDescription
			title="Improving the World with Artificial Intelligence"
			linkTo="/technologies-machine-learning"
		>
			Machine learning and AI are the science and engineering of making machines
			or computer programs more “intelligent.” Both technologies are already
			being used in manufacturing, medical industries, and business. Facebook,
			Google, Apple, and other technological giants use AI extensively. From
			facial recognition to smart homes and much more,
			<Link to={'/technologies-machine-learning'}> ML and AI </Link>
			are used to make our lives easier and improve our quality of life.{' '}
			<b>PixelTeh</b> is on the leading edge of this industry. We design,
			develop, and deploy a wide range of AI applications for our clients. Find
			out more by visiting our AI/ML page.
		</TechDescription>
		<TechFeaturesListMobile
			features={features}
			link="/technologies-machine-learning"
		/>
		<TechIcons icons={icons} />
	</ContentSection>
);

export default AI;
