import styled, { css } from 'styled-components';

const ProjectTypesLine = styled.div`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	position: relative;
	align-items: ${({ top }) => (top ? 'flex-end' : 'flex-start')};
	${({ top }) => {
		return (
			!top &&
			css`
				margin-top: -10px;
				justify-content: space-around;
				&:before {
					content: '';
					width: 100%;
					position: absolute;
					top: 5px;
					left: 0;
					right: 0;
					height: 1px;
					background: #c9c9c9;
				}
			`
		);
	}}
`;

export default ProjectTypesLine;
