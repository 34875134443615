import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CenterButton from '../_common/CenterButton';
import { colors, fonts, media } from '../../globalStyles';
import moreIcon from '../../assets/img/more-icon.png';

const StyledTechFeaturesList = styled.div`
	display: none;
	width: 100%;
	flex-direction: column;
	${media.maxSm`
		display: flex;
	`}
	.mobile-section-list {
		margin: 74px auto 30px;
		padding-left: 0;
		list-style: none;
		text-align: left;
		li {
			display: flex;
			align-items: center;
			color: ${colors.blackTitle};
			font-family: ${fonts.robotoLightItalic};
			font-size: 15px;
			line-height: 33px;
			justify-content: flex-start;
		}
		img {
			margin-right: 8px;
			height: 100%;
			width: auto;
		}
	}
`;

const TechFeaturesListMobile = ({ features, link }) => (
	<StyledTechFeaturesList>
		<ul className="mobile-section-list">
			{features.map((feature, index) => (
				<li key={index}>
					<img src={moreIcon} alt="" />
					{feature}
				</li>
			))}
		</ul>
		<CenterButton clear linkTo={link} gaLabel={'technologies Discover More'} />
	</StyledTechFeaturesList>
);

TechFeaturesListMobile.propTypes = {
	features: PropTypes.array,
	link: PropTypes.string,
};

export default TechFeaturesListMobile;
