import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import H6 from '../../_common/H3';
import P from '../../_common/P';
import moreIcon from '../../../assets/img/more-icon.png';
import styled from 'styled-components';
import { fonts, colors, media } from '../../../globalStyles';

const StyledTypographyItem = styled.div`
	text-align: center;
	max-width: 480px;
	width: 35%;
	${media.maxMd`
		width: 37%;
	`}
	&:last-child {
		margin: 45px auto 0;
		position: relative;
		${media.laptopS`
			margin-top: -10px;
			width:45%;
		`}
		${media.maxMd`
			margin-top: -100px;
			width:45%;
		`}
		&::after {
			position: absolute;
			bottom: 103%;
			left: 50%;
			display: block;
			content: '';
			width: 1px;
			height: 120px;
			background-color: #d9d9d9;
			margin: 0 auto;
			${media.laptopS`
				height: 60px;
			`}
			${media.maxMd`
				display: none;
			`}
		}
	}
	${P} {
		margin-top: 16px;
		color: ${colors.blackTitle};
		font-family: ${fonts.openSans};
		font-size: 15px;
		line-height: 30px;
	}

	${H6} {
		margin-top: 25px;
		font-family: ${fonts.robotoMedium};
		font-size: 22px;
		color: ${colors.orange};
	}
	a {
		margin-left: 7px;
	}
	.mobile-typography-img-cover {
		margin: auto;
		background-color: rgba(244, 244, 244, 0.4);
		box-shadow: 0 0 21px rgba(0, 0, 0, 0.13);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 152px;
		width: 152px;
		height: 152px;
	}
`;

const TypographyItem = ({ icon, title, link, children, altText }) => (
	<StyledTypographyItem className="hidden-sm">
		<div className="mobile-typography-img-cover">
			<img src={icon} alt={altText} />{' '}
		</div>
		<H6>{title}</H6>
		<P>
			{children}{' '}
			<Link to={link}>
				<img src={moreIcon} alt="more link" />{' '}
			</Link>
		</P>
	</StyledTypographyItem>
);

TypographyItem.propTypes = {
	icon: PropTypes.any,
	title: PropTypes.string,
	link: PropTypes.string,
	children: PropTypes.any,
	altText: PropTypes.string,
};
export default TypographyItem;
