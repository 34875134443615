import styled from 'styled-components';
import { fonts, fontSizes } from '../../globalStyles';

const FeatureDescription = styled.div`
	font-size: ${fontSizes.p};
	font-family: ${fonts.robotoRegular};
	font-style: italic;
	color: #23252c;
`;

export default FeatureDescription;
